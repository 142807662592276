import { configureStore } from "@reduxjs/toolkit";
import { authenticationSlice } from "./slices/authenticationSlice";
import { graphApi } from "./apis/graphApi";
import { configApi } from "./apis/configApi";
import { setupListeners } from "@reduxjs/toolkit/query";
import { mainViewSlice } from "./slices/mainViewSlice";
import { snackbarSlice } from "./slices/snackbarSlice";
import { peopleSlice } from "./slices/peopleSlice";
import { groupsSlice } from "./slices/groupsSlice";
import { accessGroupsApi } from "./apis/accessGroupsApi";
import { usersApi } from "./apis/usersApi";
import { accessGroupsAdminsApi } from "./apis/accessGroupsAdminsApi";
import { accessGroupsUsersApi } from "./apis/accessGroupsUsersApi";
import { selfHelpSlice } from "./slices/selfHelpSlice";
import { selfHelpApi } from "./apis/selfHelpApi";

export const store = configureStore({
  reducer: {
    [authenticationSlice.name]: authenticationSlice.reducer,
    [mainViewSlice.name]: mainViewSlice.reducer,
    [snackbarSlice.name]: snackbarSlice.reducer,
    [peopleSlice.name]: peopleSlice.reducer,
    [groupsSlice.name]: groupsSlice.reducer,
    [selfHelpSlice.name]: selfHelpSlice.reducer,

    //APIs
    [graphApi.reducerPath]: graphApi.reducer,
    [configApi.reducerPath]: configApi.reducer,
    [accessGroupsApi.reducerPath]: accessGroupsApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [accessGroupsAdminsApi.reducerPath]: accessGroupsAdminsApi.reducer,
    [accessGroupsUsersApi.reducerPath]: accessGroupsUsersApi.reducer,
    [selfHelpApi.reducerPath]: selfHelpApi.reducer,
    //END APIs
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(graphApi.middleware)
      .concat(configApi.middleware)
      .concat(accessGroupsApi.middleware)
      .concat(usersApi.middleware)
      .concat(accessGroupsAdminsApi.middleware)
      .concat(accessGroupsUsersApi.middleware)
      .concat(selfHelpApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
